<template>
  <Page>
    <ClientBody ref="body" />
  </Page>
</template>

<script>
import ClientBody from '../bodies/Client.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  name: 'ClientPage',
  data() {
    return {}
  },
  components: { ClientBody }
}
</script>
